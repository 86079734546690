import React from "react";
import { TbClockHour3 } from "react-icons/tb";
import { IoCallSharp } from "react-icons/io5";
import { useMediaQuery } from "react-responsive";
import styles from './Button.module.scss';

export const FixedButton = () => {
    const isMobile = useMediaQuery({ query: '(max-width: 980px)' });
    const phoneNumber = '1533-8848';

    return (
        <div className={styles.fixedButtonBox}>
            <a href='https://naver.me/FWfnlWCx' className={styles.visitedButton} target='_blank'>
                <TbClockHour3 size={20} color="#FFFFFF" />
                <div>방문예약</div>
            </a>
            {isMobile && <a href={`tel:${phoneNumber}`} className={styles.callButton}>
                <IoCallSharp size={20} color="#FFFFFF" />
                <div>상담문의</div>
            </a>}
        </div>
    )
}