import React from "react";

import styles from './Button.module.scss';

export const ButtonType1 = ({ title, isClick, onClicked }) => {

	const isClicked = (type) => {
		onClicked(type);
	}

	return (
		<button
			className={`${styles.buttonBox} ${title === isClick ? styles.clickedButton : styles.noClickedbuttonBox}`}
			onClick={() => isClicked(title)}
		>
			{title}
		</button>)
}