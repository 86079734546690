import { BrowserRouter, Route, Routes } from 'react-router-dom'
import { RecoilRoot } from 'recoil';

import BusinessGuide from './pages/BusinessGuide/BusinessGuide';
import Main from './pages/Main/Main';
import LocationEnvironment from './pages/LocationEnvironment/LocationEnvironment';
import Premium from './pages/Premium/Premium';
import GuideComplex from './pages/GuideComplex/GuideComplex';
import EquilibriumGuide from './pages/EquilibriumGuide/EquilibriumGuide';

function App() {

  return (
    <BrowserRouter>
      <RecoilRoot>
        <Routes>
          <Route path='/' element={<Main />} />
          <Route path="/BusinessGuide" element={<BusinessGuide />} />
          <Route path="/LocationEnvironment" element={<LocationEnvironment />} />
          <Route path="/Premium" element={<Premium />} />
          <Route path="/GuideComplex" element={<GuideComplex />} />
          <Route path="/EquilibriumGuide" element={<EquilibriumGuide />} />
          <Route path="/*" element={<h1>Not Found</h1>} />
        </Routes>
      </RecoilRoot>
    </BrowserRouter>
  );
}

export default App;
