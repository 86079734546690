import React from 'react';
import { useLocation } from 'react-router-dom';

import styles from './LocationEnvironment.module.scss';
import { Header } from '../../components/Header/Header';
import { Footer } from '../../components/Footer/Footer';
import { FixedButton } from '../../components/Button/FixedButton';
import { IpBox } from '../../components/IpBox/IpBox';
import page1 from '../../assets/images/LocationEnvironment/page1.png';
import page2 from '../../assets/images/LocationEnvironment/page2.png';
import page3 from '../../assets/images/LocationEnvironment/page3.png';
import page4 from '../../assets/images/LocationEnvironment/page4.png';

// 입지환경
const LocationEnvironment = () => {
  const location = useLocation();
  const currentPath = location.pathname;

  return (
    <div>
      <Header pathName={currentPath} />

      <div className={styles.container}>
        <div className={styles.imageBox}>
          <img className={styles.image} src={page1} alt="seo-LocationEnvironment-firstimage" />
          <img className={styles.image} src={page2} alt="seo-LocationEnvironment-secondimage" />
          <img className={styles.image} src={page3} alt="seo-LocationEnvironment-thirdimage" />
          <img className={styles.image} src={page4} alt="seo-LocationEnvironment-fourhtimage" />
        </div>
      </div>

      <Footer />
      <FixedButton />
      {/*<IpBox />*/}
    </div>
  );
};

export default LocationEnvironment;
