import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';

import styles from './EquilibriumGuide.module.scss';
import { Header } from '../../components/Header/Header';
import { Footer } from '../../components/Footer/Footer';
import { ButtonType1 } from '../../components/Button/ButtonType1';
import { FixedButton } from '../../components/Button/FixedButton';
import { IpBox } from '../../components/IpBox/IpBox';
import page1 from '../../assets/images/EquilibriumGuide/84a.jpg';
import page2 from '../../assets/images/EquilibriumGuide/84b.jpg';
import page3 from '../../assets/images/EquilibriumGuide/103.jpg';

// 평형안내
const EquilibriumGuide = () => {
  const location = useLocation();
  const currentPath = location.pathname;
  const [isClicked, setIsClicked] = useState("전체");

  return (
    <div>
      <Header pathName={currentPath} />

      <div className={styles.container}>
        
        <div>
          <div className={styles.buttonBoxTitle}>평형 선택</div>
          <div className={styles.buttonBox}>
            <ButtonType1 title="전체" isClick={isClicked} onClicked={(e) => setIsClicked(e)} />
            <ButtonType1 title="84A" isClick={isClicked} onClicked={(e) => setIsClicked(e)} />
            <ButtonType1 title="84B" isClick={isClicked} onClicked={(e) => setIsClicked(e)} />
            <ButtonType1 title="103" isClick={isClicked} onClicked={(e) => setIsClicked(e)} />
          </div>
        </div>

        <div className={styles.imageBox}>
          <ImageBoxContainer type={isClicked} />
        </div>
      </div>

      <Footer />
      <FixedButton />
      {/*<IpBox />*/}
    </div>
  );
};

export default EquilibriumGuide;


const ImageBoxContainer = ({ type }) => {
  if (type === "84A") {
    return (
        <img className={styles.image} src={page1} alt="seo-84A-image" />
    )
  } else if (type === "84B") {
    return (
        <img className={styles.image} src={page2} alt="seo-84B-image" />
    )
  } else if (type === "103") {
    return (
        <img className={styles.image} src={page3} alt="seo-103A-image" />
    )
  } else {
    return (
      <>
        <img className={styles.image} src={page1} alt="seo-84A-image" />
        <img className={styles.image} src={page2} alt="seo-84B-image" />
        <img className={styles.image} src={page3} alt="seo-103A-image" />
      </>
    )
  }
}
