import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import { useRecoilState } from 'recoil';

import styles from './Main.module.scss';
import { Header } from '../../components/Header/Header';
import { Footer } from '../../components/Footer/Footer';
import { FixedButton } from '../../components/Button/FixedButton';
import { IpBox } from '../../components/IpBox/IpBox';
import { MainMenuBox } from '../../components/MainMenuBox/MainMenuBox';
import { AdvertModal } from '../../components/AdvertModal/AdvertModal';
import { fetchData, getData } from '../../api/instance';
import { ipState } from '../../recoil/atom';
import mainImage from '../../assets/images/Main/mainImage.jpg';
import page1 from '../../assets/images/Main/page1.jpg';
import page2 from '../../assets/images/Main/page2.jpg';
import page3 from '../../assets/images/Main/page3.jpg';
import page4 from '../../assets/images/Main/page4.jpg';
import page5 from '../../assets/images/Main/page5.jpg';
import page6 from '../../assets/images/Main/page6.jpg';
import modalImage1 from '../../assets/images/Modal/page1.jpg';
import modalImage2 from '../../assets/images/Modal/page2.jpg';

// HOME
const Main = () => {
  const location = useLocation();
  const currentPath = location.pathname;
  const isMobile = useMediaQuery({ query: '(max-width: 650px)' });
  const [ip, setIp] = useState('');
  const [ipData, setIpData] = useRecoilState(ipState);
  const [isModal, setIsModal] = useState(false);

  useEffect(() => {
    const fetchDataFromBackend = async () => {
      try {
        const result = await fetchData();
        setIp(result);
      } catch (error) {
        // 에러 처리
      }
    };

    fetchDataFromBackend();
  }, []);

  useEffect(() => {
    const getIpDataFromBackend = async (ipData) => {
      try {
        const result = await getData(ipData);
        setIpData(result);
      } catch (error) {
        // 에러 처리
      }
    };

    if (ip !== '') getIpDataFromBackend(ip);
  }, [ip]);

  //총 합 구하기
  useEffect(() => {
    if (ipData && ipData.access && ipData.access.length > 0 && !ipData.accessTotal) {
      const array = ipData.access;
      var total = 0;
      for (var i = 0; i < array.length; i++) {
        total = total + array[i].accessNumber;
      }
      setIpData((prev) => ({ ...prev, accessToday: array[array.length - 1].accessNumber, accessTotal: total }));
      setIsModal(!isModal);
    }
  }, [ipData, setIpData]);

  return (
    <div>
      <Header pathName={currentPath} />

      <AdvertModal image={modalImage1} place={0}/>
      <AdvertModal image={modalImage2} place={1}/>

      <div className={styles.container}>
        <img className={styles.mainImage} src={mainImage} alt="main" />

        <div className={styles.page}>
          <img src={page1} alt="seo-main-firstimage" />
          <img src={page2} alt="seo-main-secondimage" />
          <img src={page3} alt="seo-main-thirdimage" />
          <img src={page4} alt="seo-main-forthimage" />
          <img src={page5} alt="seo-main-fifhtimage" />
          <img src={page6} alt="seo-main-sixthimage" />
        </div>

        <MainMenuBox type={isMobile} />
        <Footer />
        <FixedButton />
        {/* <IpBox /> */}
      </div>
    </div>
  );
};

export default Main;
