import { atom } from 'recoil';

export const ipState = atom({
    key: 'ipState',
    default: {},
});

export const isIpBoxState = atom({
    key: 'isIpBoxState',
    default: true
})